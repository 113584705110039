// Overwrite Bootstrap's variables here
// You can find them in node_modules/bootstrap/scss/_variables.scss
// Copy the variables you need into this file, don't modify files under node_modules/

// Some example variables that you can uncomment:

// Enabling shadows and gradients
//$enable-shadows: true;
//$enable-gradients: true;

// Changing the body background and text
//$body-bg: #d3e9eb;
//$body-color: #151417;

// Changing the border radius of buttons
//$border-radius: 15px;

// Changing the theme colors
//$primary: #202f41;
//$secondary: #436296;
//$success: #2bc550;
//$info: #495dff;
//$warning: #ef8143;
//$danger: #ff293a;
//$light: #dfe6ee;
//$dark: #0f1319;


// Adding (!) an additional theme color (ex. classes btn-cool, bg-cool)
//$theme-colors: (
//  "cool": #4d3fa3
//);

// Custom:  Societ Theme color
$blue:    #8c84d7 !default;      // societ
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #00c19f !default;     // societ
$teal:    #00a48b !default;     // societ
$cyan:    #17a2b8 !default;


// Custom:  Societ Theme color
$primary:          #00c19f;     // $green;
$primary-light:    #81dfcf;
$primary-light2:   #c0efe7;
$primary-hover:    #00a48b;
$primary-focus:    #00a48b;
$primary-active:   #028370;

$secondary:        #8c84d7;
$secondary-hover:  #7565c6;
$secondary-focus:  #7565c6;
$secondary-active: #9254ae;
$secondary-light:  #c6c1e9;
$secondary-light2: #e2e0f5;

$tertiary:         #003940;

$gray-dark:        #d4d9d8;
$gray-medium:      #e1e5e4;
$gray-light:       #edf2f1;

$gray-shader1:   #d4d8d7;
$gray-shader2:   #e0e4e3;
$gray-shader3:   #edeff1;

$theme-colors: () !default;
$theme-colors: map-merge(
  (
    "primary":    $primary,
    "secondary":  $secondary,
    "tertiary":         $tertiary,
    "primary_light":    $primary-light,
    "primary_light2":   $primary-light2,
    "primary_hover":    $primary-hover,
    "primary_focus":    $primary-focus,
    "primary_active":   $primary-active,
    "secondary_hover":  $secondary-hover,
    "secondary_focus":  $secondary-focus,
    "secondary_active": $secondary-active,
    "secondary_light":  $secondary-light,
    "secondary_light2": $secondary-light2,
    "gray_dark":        $gray-dark,
    "gray_medium":      $gray-medium,
    "gray_light":       $gray-light,
    "gray_shader1":     $gray-shader1,
    "gray_shader2":     $gray-shader2,
    "gray_shader3":     $gray-shader3
  ),
  $theme-colors
);

// _types.css:
$font-size-base:              1rem !default; // Assumes the browser default, typically `16px`
$h1-font-size:                $font-size-base * 3 !default;
$h2-font-size:                $font-size-base * 2.25 !default;
$h3-font-size:                $font-size-base * 1.875 !default;
$h4-font-size:                $font-size-base * 1.5 !default;
$h5-font-size:                $font-size-base * 1.25 !default;
$h6-font-size:                $font-size-base * 1.125 !default;


$font-family-sans-serif:      'Helvetica Neue', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
